import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Storefront } from '../models/StorefrontResponse';
import { User } from '../models/UsersResponse';
import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useSuppressionFileUpload } from '../hooks/useSuppressionFileUpload';
import { APIEndpoint, EndpointType, httpPut } from '../utils/apiService';
import { SuppressionListResponse, SuppressionListStatus } from '../models/Suppression';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    storefront: Storefront
    user: User | undefined
    referenceID: string
    close(): void
}

export default function SuppressionUpload(props: Props) {
    const [uploadFormData, setUploadFormData] = React.useState<FormData>(new FormData())
    const [startUpload, setStartUpload] = useState(false)
    const [accessToken, setAccessToken] = useState("")
    const [firstNameMap, setFirstNameMap] = useState("Not Used")
    const [lastNameMap, setLastNameMap] = useState("Not Used")
    const [addressMap, setAddressMap] = useState("Not Used")
    const [address2Map, setAddress2Map] = useState("Not Used")
    const [zipMap, setZipMap] = useState("Not Used")
    const [phoneMap, setPhoneMap] = useState("Not Used")
    const [emailMap, setEmailMap] = useState("Not Used")
    const [warningMessage, setWarningMessage] = useState("")
    const [fileUploadLimitWarning, setFileUploadLimitWarning] = useState("")

    // Hooks
    const { suppressionUploadCompleted, suppressionUploadError, suppressionUploadList, suppressionUploadProgress } = useSuppressionFileUpload(uploadFormData, startUpload, props.referenceID, accessToken)

    useEffect(() => {
        getAccessToken()
    }, []);

    useEffect(() => {
        console.log("lastNameMap: " + lastNameMap)
        console.log("firstNameMap: " + firstNameMap)
        console.log("addressMap: " + addressMap)
        console.log("address2Map: " + address2Map)
        console.log("zipMap: " + zipMap)
        console.log("phoneMap: " + phoneMap)
        console.log("emailMap: " + emailMap)

        getIdxFromFieldName(addressMap)
        getIdxFromFieldName(zipMap)

    }, [firstNameMap, lastNameMap, addressMap, address2Map, zipMap, phoneMap, emailMap]);

    const uploadFilenameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileUploadLimitWarning("")
        const files = event.target.files;
        if (files) {
            const file = files[0];

            // Verify the file is less than 5MB
            const size = (file.size);
            console.log("Upload file size: " + size)

            if (size > 4500000) {
                setFileUploadLimitWarning("File is too large! Size must be less than 4.5MB")
                return
            }

            const formData = new FormData();
            formData.append('file', file);
            setUploadFormData(formData);
        }
    }

    const uploadButtonClicked = () => {
        if (uploadFormData.get("file") == null) {
            return
        }
        setStartUpload(true)
    }

    const submitButtonClicked = () => {
        setWarningMessage("")
        // Validate that required fields are mapped
        if (addressMap == "-1") {
            setWarningMessage("The Address field must be mapped to a valid field")
            return
        }

        if (zipMap == "-1") {
            setWarningMessage("The Zip field must be mapped to a valid field")
            return
        }

        const suppression = suppressionUploadList

        if (suppression) {
            suppression.status = SuppressionListStatus.ready_for_processing
            suppression.columnMappings = {}
            suppression.columnMappings["address"] = getIdxFromFieldName(addressMap)
            suppression.columnMappings["zip"] = getIdxFromFieldName(zipMap)

            if (firstNameMap != "Not Used") {
                suppression.columnMappings["firstName"] = getIdxFromFieldName(firstNameMap)
            }

            if (lastNameMap != "Not Used") {
                suppression.columnMappings["lastName"] = getIdxFromFieldName(lastNameMap)
            }

            if (address2Map != "Not Used") {
                suppression.columnMappings["address2"] = getIdxFromFieldName(address2Map)
            }

            if (phoneMap != "Not Used") {
                suppression.columnMappings["phone"] = getIdxFromFieldName(phoneMap)
            }

            if (emailMap != "Not Used") {
                suppression.columnMappings["email"] = getIdxFromFieldName(emailMap)
            }

            console.log("Colum Mappings: " + JSON.stringify(suppression.columnMappings))

            try {
                const apiURL = APIEndpoint(EndpointType.Suppressions) + "/" + suppression.id;
                httpPut(apiURL, JSON.stringify(suppression))
                    .then((data) => {
                        const response = data as SuppressionListResponse;

                        if (response.status == 'error') {
                            setWarningMessage("Error creating transaction! " + response.errorMessage)
                        } else {
                            alert("The file has been submitted for processing. You will receive an email notification within the next several minutes upon its completion.")
                            props.close()
                        }
                    })
                    .catch((error) => {
                        setWarningMessage(error)
                    });
            } catch (error) {
                setWarningMessage("failed to submit suppression list")
            }
        }
    }

    const getIdxFromFieldName = (fieldName: string): number => {
        if (!suppressionUploadList) {
            return -1
        }

        const idx = suppressionUploadList.fields.indexOf(fieldName)
        console.log(fieldName, idx)
        return idx
    }

    async function getAccessToken() {
        // Get the access token
        const currentSession = Auth.currentSession()
        const token = (await currentSession).getAccessToken()
        const accessToken = token.getJwtToken()
        setAccessToken(accessToken)
    }

    return (
        <Container maxWidth="xl" disableGutters={true} sx={{}}>
            <Paper >
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Typography variant="h5" sx={{ ml: 1, color: props.storefront.activeColors.mainPage.pageText }}>Suppression List Upload</Typography>
                    <IconButton sx={{ marginTop: -.5, color: props.storefront.activeColors.mainPage.pageText }} size="small" type="button" onClick={() => props.close()} >
                        <CloseIcon />
                    </IconButton>
                </Stack>

                {!suppressionUploadCompleted &&
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1} sx={{ ml: 1 }}>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText }}>Submit a suppression file to ensure that you don't receive duplicate data when placing orders.</Typography>
                            <Typography variant="body1" sx={{ color: props.storefront.activeColors.mainPage.pageText, fontWeight: "bold" }}>File Requirements:</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Supported file types: .csv, .xls, .xlsx</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Minimum required fields are Address and Zip</Typography>
                            <Typography variant="body1" sx={{ pl: 2, color: props.storefront.activeColors.mainPage.pageText }}>&bull; Supported fields: First Name, Last Name, Address, Address 2, Zip, Phone, Email & Company</Typography>
                        </Stack>

                        <FormControl>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2, mb: 1, ml: 1 }}>
                                <TextField type="file" onChange={uploadFilenameChanged} inputProps={{ accept: ".csv,.xls,.xlsx" }} sx={{ width: 648 }} />
                                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={uploadButtonClicked}>Upload</Button>
                            </Stack>
                            {fileUploadLimitWarning != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1, mt:1 }}>{fileUploadLimitWarning}</Alert>}
                        </FormControl>
                    </Box>
                }

                {suppressionUploadCompleted
                    ? <Grid sx={{ mt: 1, ml: 1, mr: 1 }}>
                        {suppressionUploadList && suppressionUploadList.id != "" && suppressionUploadError == "" &&
                            <Grid>
                                <Typography variant="h6">Field Mapping:</Typography>
                                <Typography variant="body1">In order to complete the suppression file submission process, fields must be mapped so we know which ones to use. Address and Zip are the only required fields.</Typography>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2, pb: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>First Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setFirstNameMap(e.target.value))} size="small" value={firstNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Last Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setLastNameMap(e.target.value))} size="small" value={lastNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Address:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setAddressMap(e.target.value))} size="small" value={addressMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Address 2:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setAddress2Map(e.target.value))} size="small" value={address2Map}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Zip Code:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setZipMap(e.target.value))} size="small" value={zipMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Phone:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setPhoneMap(e.target.value))} size="small" value={phoneMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 100 }}>Email:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setEmailMap(e.target.value))} size="small" value={emailMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {suppressionUploadList && suppressionUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {warningMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{warningMessage}</Alert>}
                                <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, pb: 1 }}>
                                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={submitButtonClicked}>Complete Submission</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    : <Grid>
                        {suppressionUploadProgress > 0 &&
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1 }}>
                                <Typography variant="body1" sx={{ width: 120 }}>Uploading file...</Typography>
                                <Box sx={{ width: '81%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={suppressionUploadProgress} />
                                </Box>
                            </Stack>
                        }
                    </Grid>
                }

                {suppressionUploadError && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{suppressionUploadError}</Alert>}
            </Paper>
        </Container>
    );
}
