import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SalesListItems, MainListItems, StoreManagerListItems, NoAdminListItems, FeatureListItems, APIManagerListItems } from "./ListItems";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Tooltip, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import MyOrders from "../pages/MyOrders";
import Users from "../pages/Users";
import ApiDocs from "../pages/APIDocs";
import Profile from "../pages/Profile";
import Home from "../pages/Home";
import Notifications from "../pages/Notifications";
import useAuth from "../hooks/useAuth";
import Orders from "../pages/Orders";
import PendingOrders from "../pages/PendingOrders";
import NewOrder from "../pages/NewOrder";
import ZipCodeCounts from "../pages/ZipCodeCounts";
import ChangePassword from "../pages/ChangePassword";
import FAQ from "../pages/FAQ";
import ManageAnnouncements from "../pages/ManageAnnouncements";
import LeadsStore from "../pages/LeadsShop";
import SalesDashboard from "../pages/SalesDashboard";
import StoreManager from "../pages/StoreManager";
import MySubscriptions from "../pages/Subscriptions"
import { useCompany } from "../hooks/useCompany";
import { useUser } from "../hooks/useUser";
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { useStorefrontManager } from "../hooks/useStorefrontManager";
import { Storefront } from "../models/StorefrontResponse";
import { getEnvironment } from "../utils/apiService";
import Footer from "../components/Footer";
import OrderDetailsPage from "../pages/OrderDetailsPage";
import { ParseQueryString } from "../utils/apiService";
import ApiIcon from '@mui/icons-material/Api';
import WalletManagement from "../pages/WalletManagement";
import MyWallet from "../pages/MyWallet";
import LeadsVisualizer from "../pages/LeadVisualizer";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { User } from "../models/UsersResponse";
import Calculators from "../pages/Calculators";
import Products from "../pages/Products";
import ListBuilder from "../pages/ListBuilder";
import Customers from "../pages/Customers"
import APILogs from "../pages/APILogs";
import Lookup from "../pages/Lookup";
import LeadEnhancement from "../pages/LeadEnhancement";
import LoginAs from "./LoginAs";
import LoginIcon from '@mui/icons-material/Login';
import CompressIcon from '@mui/icons-material/Compress';
import Suppressions from "../pages/Suppressions";
import Mortgage from "../pages/Mortgage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const drawerWidth: number = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [companyID, setCompanyID] = useState("")
  const [userID, setUserID] = useState("")
  const [loginAsUser, setLoginAsUser] = useState<User>()
  const [showStorefrontManager, setShowStorefrontManager] = useState(false)
  const [showOrders, setShowOrders] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isTSGUser, setIsTSGUser] = useState(false)
  const [wasAuthenticated, setWasAuthenticated] = useState(false)
  const { isAuthenticating, isAuthenticated, signOut, user } = useAuth();
  const [storefrontName, setStorefrontName] = useState("");
  const { storefronts } = useStorefrontManager(storefrontName)
  const { company } = useCompany(companyID);
  const { customer } = useUser(userID);
  const [walletBalanceRefreshKey, setWalletBalanceRefreshKey] = useState(0);
  const [logoUrl, setLogoUrl] = useState("")
  const [environment, setEnvironment] = useState("prod");
  const [loginButtonDisplay, setLoginButtonDisplay] = useState('inline');
  const [storefrontOverride, setStorefrontOverride] = useState(false);
  const { walletBalance } = useWalletBalance(activeUserID(), walletBalanceRefreshKey)
  const [storefront, setStorefront] = useState<Storefront>({
    id: "",
    storeName: "",
    title: "Lead Shop",
    createdAt: "",
    modifiedAt: "",
    emailSignatureHTML: "",
    products: [],
    companyID: "",
    minOrderAmount: 200,
    lookupWalletCredits: 5,
    darkMode: {
      logo: "",
      mainPage: {
        pageButtonBackground: "#ffffff",
        pageIconBackground: "#5462E9",
        pageText: "#201E1F",
        pageMapBorderBackground: "#f2f2f2",
        pageSearchBoxBackground: "#ffffff",
        pageSearchBoxText: "#080b16",
        headerBackground: "#ffffff",
        headerText: "#000000",
        avatarBackground: "#e8e8eb",
        avatarForeground: "5462E9"
      },
      cart: {
        cartBackground: "#efefef",
        cartProductBackground: "#ffffff",
        cartProductRemoveIcon: "#f65c5c",
        cartPurchaseTotalBackground: "#ffffff",
        cartTitleText: "#000000",
        cartProductText: "#000000",
        cartPurchaseText: "#000000",
        cartIconBackground: "#000000",
        cartButtonBackground: "#FE5B35",
        cartPurchaseProgressBackground: "#ffffff",
      },
      addProductForm: {
        formBackground: "#fafafa",
        titleText: "#000000",
        productTitleText: "#444444",
        productText: "#000000",
        iconForeground: "#404242",
        buttonText: "#ffffff",
        buttonBackground: "#1c72b0"
      },
      leftMenu: {
        divider: "#80848E",
        icon: "#80848E"
      },
      pageFooter: {
        text: "#000000"
      }
    },
    lightMode: {
      logo: "",
      mainPage: {
        pageButtonBackground: "#ffffff",
        pageIconBackground: "#5462E9",
        pageText: "#201E1F",
        pageMapBorderBackground: "#f2f2f2",
        pageSearchBoxBackground: "#ffffff",
        pageSearchBoxText: "#080b16",
        headerBackground: "#ffffff",
        headerText: "#000000",
        avatarBackground: "#e8e8eb",
        avatarForeground: "5462E9"
      },
      cart: {
        cartBackground: "#efefef",
        cartProductBackground: "#ffffff",
        cartProductRemoveIcon: "#f65c5c",
        cartPurchaseTotalBackground: "#ffffff",
        cartTitleText: "#000000",
        cartProductText: "#000000",
        cartPurchaseText: "#000000",
        cartIconBackground: "#000000",
        cartButtonBackground: "#FE5B35",
        cartPurchaseProgressBackground: "#ffffff",
      },
      addProductForm: {
        formBackground: "#fafafa",
        titleText: "#000000",
        productTitleText: "#444444",
        productText: "#000000",
        iconForeground: "#404242",
        buttonText: "#ffffff",
        buttonBackground: "#1c72b0"
      },
      leftMenu: {
        divider: "#80848E",
        icon: "#80848E"
      },
      pageFooter: {
        text: "#000000"
      }
    },
    activeColors: {
      logo: "",
      mainPage: {
        pageButtonBackground: "#ffffff",
        pageIconBackground: "#5462E9",
        pageText: "#201E1F",
        pageMapBorderBackground: "#f2f2f2",
        pageSearchBoxBackground: "#ffffff",
        pageSearchBoxText: "#080b16",
        headerBackground: "#ffffff",
        headerText: "#000000",
        avatarBackground: "#e8e8eb",
        avatarForeground: "5462E9"
      },
      cart: {
        cartBackground: "#efefef",
        cartProductBackground: "#ffffff",
        cartProductRemoveIcon: "#f65c5c",
        cartPurchaseTotalBackground: "#ffffff",
        cartTitleText: "#000000",
        cartProductText: "#000000",
        cartPurchaseText: "#000000",
        cartIconBackground: "#000000",
        cartButtonBackground: "#FE5B35",
        cartPurchaseProgressBackground: "#ffffff",
      },
      addProductForm: {
        formBackground: "#fafafa",
        titleText: "#000000",
        productTitleText: "#444444",
        productText: "#000000",
        iconForeground: "#404242",
        buttonText: "#ffffff",
        buttonBackground: "#1c72b0"
      },
      leftMenu: {
        divider: "#80848E",
        icon: "#80848E"
      },
      pageFooter: {
        text: "#000000"
      }
    }
  });

  useEffect(() => {
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/' + location.search]);
    _hsq.push(['trackPageView']);
    console.log("2 location.href:", location.href)
    console.log("2 location.search:", location.search)
    
    setEnvironment(getEnvironment())
    detectStorename()

    console.log("1 location.href:", location.href)
    console.log("1 location.search:", location.search)

    if (!isAuthenticated) {
      let newRoute = location.pathname
      if (location.pathname == "/") {

        newRoute = "/products/"
      }

      navigate(newRoute, { replace: true });
    }

    // Close the drawer is screen is small
    if (window.innerWidth < 500) {
      setOpen(false)
    }

    // Set the LoginAs user if specified
    const loginAs = localStorage.getItem("loginAs")
    if (loginAs) {
      try {
        setLoginAsUser(JSON.parse(loginAs))
      } catch (e) {
        console.log("failed to parse loginAs user")
      }
    }
  }, []);

  // useEffect(() => {
  //   // Log Pageview with Hubspot
  //   const _hsq = window._hsq = window._hsq || [];
  //   _hsq.push(['setPath', '/']);
  //   _hsq.push(['trackPageView']);
  //   console.log("2 location.href:", location.href)
  //   console.log("2 location.search:", location.search)
  // }, []);

  useEffect(() => {
    const colorMode = localStorage.getItem("colorMode")
    setIsDarkMode(colorMode == "dark")
  }, [])

  useEffect(() => {
    if (customer == undefined) {
      return
    }

    if (customer.storefrontName != "") {
      localStorage.setItem("storefront", customer.storefrontName)
    }

    if (storefrontName == "") {
      console.log("no storefront name...this shouldn't happen")
    }

    const activeSfUser = activeUser()

    // If a TSG user is overriding the storefront then ignore the storefront configured for the user
    if (activeSfUser && activeSfUser.storefrontName == "tsg" && storefrontName != "tsg" && activeSfUser.email.includes("@theshare.group")) {
      console.log("Storefront Override by TSG user! Storefront=" + storefrontName)
      setStorefrontOverride(true)
      setShowOrders(true)
      setShowStorefrontManager(true)
      return
    }

    if (activeSfUser && customer.companyID != "") {
      setCompanyID(activeSfUser.companyID)
    }


    if (activeSfUser && activeSfUser.storefrontName != "") {
      console.log("storefront specified in logged-in as user profile (" + activeSfUser.storefrontName + ")")
      setStorefrontName(activeSfUser.storefrontName)
    } else {
      setStorefrontName(customer.storefrontName)
    }

    if (activeSfUser) {
      setIsTSGUser(activeSfUser.email.includes("@theshare.group"))
    } else {
      setIsTSGUser(customer.email.includes("@theshare.group"))
    }

  }, [customer]);

  useEffect(() => {

    if (storefronts.length > 0) {
      console.log("Storefront Loaded: " + storefronts[0].storeName)
      // get the color mode to use
      const colorMode = localStorage.getItem("colorMode")
      if (colorMode == "dark") {
        storefronts[0].activeColors = storefronts[0].darkMode
      } else {
        storefronts[0].activeColors = storefronts[0].lightMode
      }

      setStorefront(storefronts[0])
    }
  }, [storefronts]);

  useEffect(() => {
    getlogoURLs();
  },)

  useEffect(() => {
    if (company == undefined) {
      return
    }

    console.log("[Dashboard] Company Loaded: " + company.name)

    // Storefront Availability
    if (company.roles.indexOf('storefront') > -1) {
      setShowStorefrontManager(true)
    } else {
      setShowStorefrontManager(false)
    }

    // View Orders Availability
    if (company.roles.indexOf('orders') > -1) {
      setShowOrders(true)
    } else {
      setShowOrders(false)
    }
  }, [company]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoginButtonDisplay('none')
      setWasAuthenticated(true)
    } else {
      setLoginButtonDisplay('inline')
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (wasAuthenticated && !isAuthenticated) {
      navigate("/signin", { replace: true });
    }
  }, [wasAuthenticated, isAuthenticated]);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const currentUserName = () => {
    const cu = activeUser()
    if (cu) {
      return cu.firstName + " " + cu.lastName
    }
  }

  function detectStorename() {
    if (storefrontName == "") {
      let sf = ""
      const queryParams = ParseQueryString(location.search)
      const sfParam = queryParams.get("sf")

      if (sfParam != undefined) {
        console.log("storefront specified in URL (" + sf + ")")
        sf = sfParam
      }

      if (isAuthenticated) {
        // See if we need to allow an override
        if (sf != "" && sf != "tsg" && (user.attributes.email + "").includes("@theshare.group")) {
          setStorefrontName(sf)
          return
        }
      }

      const activeSfUser = activeUser()
      if (activeSfUser && activeSfUser.storefrontName != "") {
        console.log("storefront specified in logged-in as user profile (" + activeSfUser.storefrontName + ")")
        sf = activeSfUser.storefrontName
      }


      if (sf == "") {
        const savedStorefront = localStorage.getItem("storefront")

        if (savedStorefront) {
          sf = savedStorefront
          console.log("storefront specified in local storage (" + sf + ")")
        }
      }

      if (sf == "") {
        console.log("storefront has no indicators, defaulting to 'tsg'")
        sf = "tsg"
      }

      setStorefrontName(sf)
    }
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const customerFeatures = (cust?: User): string[] => {
    if (cust && cust.features) {
      return cust.features
    }

    return []
  }

  const handleLogout = async () => {
    try {
      // sign out user
      await signOut();
      navigate("/signin", { replace: true });
    } catch (err) {
      console.error("Auth Error: ", err);
    }
  };

  const handleChangePassword = async () => {
    navigate("/changepassword");
  };

  const handleApiDocs = async () => {
    navigate("/apidocs");
    handleCloseUserMenu();
  };

  const handleLoginAs = async () => {
    navigate("/loginas");
    handleCloseUserMenu();
  };

  const handleSuppressions = async () => {
    navigate("/suppressions");
    handleCloseUserMenu();
  };

  const handleProfileSettings = () => {
    setAnchorElUser(null);
    navigate("/profile")
  }

  const onDarkModeChanged = () => {
    setAnchorElUser(null);
    const newMode = !isDarkMode
    const colorMode = newMode ? "dark" : "light"

    localStorage.setItem("colorMode", colorMode)
    setIsDarkMode(newMode)
    location.reload();
  };

  function activeUserID(): string {
    if (loginAsUser && loginAsUser.id != "") {
      return loginAsUser.id
    } else {
      return userID
    }
  }

  function activeUser() {
    if (loginAsUser && loginAsUser.id != "") {
      return loginAsUser
    } else {
      return customer
    }
  }

  function AdminListComponent() {
    if (isInSalesGroup() && !storefrontOverride) {
      return <SalesListItems storefront={storefront} features={customerFeatures(activeUser())} isTSG={isTSGUser} />
    }

    return <NoAdminListItems />
  }

  function StoreManagerListComponent() {
    return StoreManagerListItems(showStorefrontManager, showOrders, storefront)
  }

  function APIListComponent() {
    if (company != undefined && company.roles.indexOf('query_api') > -1) {
      return APIManagerListItems(storefront)
    } else {
      return <div></div>
    }
  }

  function FeatureListComponent() {
    return <FeatureListItems storefront={storefront} features={customerFeatures(activeUser())} isTSG={isTSGUser} />
  }

  function isInSalesGroup() {
    try {
      const activeSfUser = activeUser()
      if (activeSfUser && String(activeSfUser.email).includes("@theshare.group")) {
        return true;
      }

      // if (user.signInUserSession.accessToken.payload['cognito:groups'] != undefined) {
      //   if (user.signInUserSession.accessToken.payload['cognito:groups'].length > 0) {
      //     if (user.signInUserSession.accessToken.payload['cognito:groups'].length === 1) {
      //       if (user.signInUserSession.accessToken.payload['cognito:groups'][0] === 'Sales') {
      //         return true;
      //       }
      //     }
      //   }
      // }
    } catch (err: unknown) {
      return false
    }
    return false
  }

  const getlogoURLs = () => {
    if (storefrontName == "") {
      return
    }

    if (storefront.activeColors.logo == "") {
      setLogoUrl("")
    } else {
      if (isDarkMode) {
        if (environment == "prod") {
          setLogoUrl("https://shop.theshare.group/storefront/" + storefront.storeName + "_logo_dark.png")
        } else {
          setLogoUrl("https://" + environment + ".portal.theshare.group/storefront/" + storefront.storeName + "_logo_dark.png")
        }
      } else {
        if (environment == "prod") {
          setLogoUrl("https://shop.theshare.group/storefront/" + storefront.storeName + "_logo_light.png")
        } else {
          setLogoUrl("https://" + environment + ".portal.theshare.group/storefront/" + storefront.storeName + "_logo_light.png")
        }
      }
    }
  }

  useEffect(() => {
    // if authenticated then track the user for HubSpot
    if (isAuthenticated) {
      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(["identify", {
        email: user.attributes.email,
        id: user.username
      }]);

      try {
        const activeSfUser = activeUser()
        if (activeSfUser && activeSfUser.id != "") {
          setUserID(activeSfUser.id)
        } else {
          setUserID(user.username)
        }
      }
      catch (e) {
        console.log(e)
        navigate("/signin")
      }
    }
  }, [isAuthenticating, isAuthenticated]);

  const handleLogin = () => {
    navigate("/signin");
  }

  const walletBalanceChanged = () => {
    console.log("DEBUG: refreshing wallet balance")
    setWalletBalanceRefreshKey(walletBalanceRefreshKey + 1)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isAuthenticated
        ? <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: storefront.activeColors.mainPage.headerBackground
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                color: storefront.activeColors.leftMenu.icon,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {logoUrl == ""
              ? <div />
              : storefront.storeName == "tsg" || storefront.storeName == ""
                ? <a href="https://theshare.group"><img src={logoUrl} height={48} /></a>
                : <img src={logoUrl} height={48} />
            }

            <Typography
              component="h1"
              variant="h6"
              color={storefront.activeColors.mainPage.headerText}
              noWrap
              fontWeight="fontWeightMedium"
              sx={{ flexGrow: 1, padding: '10px' }}
            >
              {storefront?.title}
            </Typography>
            {loginAsUser && loginAsUser.id != "" 
              ? <Chip color="primary" sx={{ mr: 4 }} label={"Logged-in As: " + loginAsUser.firstName + " " + loginAsUser.lastName} onClick={() => { localStorage.setItem("loginAs", ""); location.reload(); }}></Chip>
              : <Chip color="primary" sx={{ mr: 4 }} label={currentUserName()}></Chip>
            }
            <Box sx={{ flexGrow: 0, marginRight: -3 }}>
              {walletBalance && walletBalance.balance && walletBalance.balance > 0
                ? <Chip label={"Wallet Balance: " + walletBalance.balance} onClick={() => navigate('/mywallet')}></Chip>
                : <div />
              }
              <Tooltip title="Open settings">
                <IconButton
                  size="medium"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, mx: 2 }}
                >
                  <Avatar alt="" sx={{ color: storefront.activeColors.mainPage.avatarForeground, backgroundColor: storefront.activeColors.mainPage.avatarBackground }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleProfileSettings}>
                  <ListItemIcon>
                    <Person2OutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Profile Settings</Typography>
                </MenuItem>
                <Divider />
                <MenuItem key="darkMode" onClick={onDarkModeChanged}>
                  <ListItemIcon>
                    {isDarkMode
                      ? <CheckBoxOutlinedIcon />
                      : <CheckBoxOutlineBlankOutlinedIcon />
                    }
                  </ListItemIcon>
                  Dark Mode
                </MenuItem>
                <MenuItem key="change-password" onClick={handleChangePassword}>
                  <ListItemIcon>
                    <LockResetOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                {isTSGUser &&
                  <MenuItem key="loginas" onClick={handleLoginAs}>
                    <ListItemIcon>
                      <LoginIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">Login As...</Typography>
                  </MenuItem>
                }
                {company != undefined && company.roles.indexOf('query_api') > -1 &&
                  <MenuItem key="api-docs" onClick={handleApiDocs}>
                    <ListItemIcon>
                      <ApiIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography textAlign="center">API Documentation</Typography>
                  </MenuItem>
                }
                <MenuItem key="suppressions" onClick={handleSuppressions}>
                  <ListItemIcon>
                    <CompressIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Suppression Files</Typography>
                </MenuItem>
                <Divider />
                {/* <MenuItem key="api-docs" onClick={() => {navigate("/apidocs/index.html")}}>
                  <ListItemIcon>
                    <LockResetOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
              <Divider /> */}
                <MenuItem key="sign-out" onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        : <AppBar>
          <Toolbar
            sx={{
              backgroundColor: storefront.activeColors.mainPage.headerBackground,
            }}
          >
            {logoUrl == ""
              ? <div />
              : <a href="https://theshare.group"><img src={logoUrl} height={48} /></a>
            }

            {/* <Stack direction="row" justifyContent="space-evenly" alignItems="flex-end"> */}
            <Typography
              component="h1"
              variant="h5"
              color={storefront.activeColors.mainPage.headerText}
              noWrap
              fontWeight="fontWeightMedium"
              sx={{ flexGrow: 1, padding: '10px' }}
            >
              {storefront?.title}
            </Typography>
            <IconButton onClick={handleLogin} sx={{ color: storefront?.activeColors.mainPage.pageIconBackground, display: loginButtonDisplay, marginRight: -2 }}>
              <Stack direction="row">
                <Typography>Login</Typography>
                <LoginIcon />
              </Stack>
            </IconButton>
            {/* </Stack> */}
          </Toolbar>
        </AppBar>
      }
      {isAuthenticated
        ? <Drawer variant="permanent" open={open} sx={{}}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],

            }}
          >
            <IconButton type="button" onClick={toggleDrawer} sx={{ color: storefront.activeColors.leftMenu.icon }}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" sx={{}}>
            <MainListItems storefront={storefront} features={customerFeatures(activeUser())} isTSG={isTSGUser} />
            <Divider sx={{ my: 1, backgroundColor: storefront.activeColors.leftMenu.divider }} />
            <StoreManagerListComponent />
            <FeatureListComponent />
            <APIListComponent />
            <AdminListComponent />
          </List>
          <Footer position="absolute" storefrontName={storefront.storeName} />
        </Drawer>
        : <Box>
          <Footer position="absolute" storefrontName={storefront.storeName} />
        </Box>
      }
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<LeadsStore storefront={storefront} userID={activeUserID()} />} />
          <Route path="/home" element={<Home storefront={storefront} isDarkMode={isDarkMode} />} />
          <Route path="/myorders" element={<MyOrders storefront={storefront} userID={activeUserID()} />} />
          <Route path="/users" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/orders" element={<Orders storefront={storefront} />} />
          <Route path="/pendingorders" element={<PendingOrders />} />
          <Route path="/neworder" element={<NewOrder storefront={storefront} customer={activeUser()} />} />
          <Route path="/zipcodecounts" element={<ZipCodeCounts />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/announcements" element={<ManageAnnouncements />} />
          <Route path="/leadsstore" element={<LeadsStore storefront={storefront} userID={activeUserID()} />} />
          <Route path="/products/:id" element={<LeadsStore storefront={storefront} userID={activeUserID()} />} />
          <Route path="/products" element={<LeadsStore storefront={storefront} userID={activeUserID()} />} />
          <Route path="/salesdashboard" element={<SalesDashboard />} />
          <Route path="/storefront" element={<StoreManager storefront={storefront} storefrontoverride={storefrontOverride} />} />
          <Route path="/mysubscriptions" element={<MySubscriptions storefront={storefront} />} />
          <Route path="/order" element={<OrderDetailsPage />} />
          <Route path="/apidocs" element={<ApiDocs />} />
          <Route path="/leadsvisualizer" element={<LeadsVisualizer storefront={storefront} />} />
          <Route path="/walletmanagement" element={<WalletManagement storefront={storefront} walletBalanceChanged={walletBalanceChanged} />} />
          <Route path="/mywallet" element={<MyWallet storefront={storefront} user={activeUser()} />} />
          <Route path="/calculators" element={<Calculators />} />
          <Route path="/storeproducts" element={<Products storefront={storefront} />} />
          <Route path="/listbuilder" element={<ListBuilder storefront={storefront} isTSGUser={isTSGUser} customer={activeUser()} />} />
          <Route path="/customers" element={<Customers storefront={storefront} />} />
          <Route path="/apilogs" element={<APILogs storefront={storefront} customer={activeUser()} />} />
          <Route path="/lookup" element={<Lookup storefront={storefront} customer={activeUser()} />} />
          <Route path="/leadenhancement" element={<LeadEnhancement storefront={storefront} customer={activeUser()} />} />
          <Route path="/loginas" element={<LoginAs />} />
          <Route path="/suppressions" element={<Suppressions storefront={storefront} User={activeUser()} />} />
          <Route path="/mortgage" element={<Mortgage storefront={storefront} customer={activeUser()} />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
